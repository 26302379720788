@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Cormorant:ital,wght@0,300..700;1,300..700&family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=El+Messiri:wght@400..700&family=Ibarra+Real+Nova:ital,wght@0,400..700;1,400..700&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Lora:ital,wght@0,400..700;1,400..700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Noto+Serif:ital,wght@0,100..900;1,100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap&crossorigin=anonymous');

$Josefin_Sans: 'Josefin Sans', sans-serif;
$Ibarra_Real_Nova: 'Ibarra Real Nova', serif;
$Montserrat: 'Montserrat', sans-serif;
$Playfair_Display: 'Playfair Display', serif;
$Lora: 'Lora', serif;
$Noto_Serif: 'Noto Serif', serif;
$EB_Garamond: 'EB Garamond', serif;
$Cormorant_Garamond: 'Cormorant Garamond', serif;
$Cormorant: 'Cormorant', serif;
$El_Messiri: 'El Messiri', sans-serif;
$Raleway: 'Raleway', sans-serif;

$bg: rgb(241, 240, 238);
$black: rgb(0, 0, 0);
$white: rgb(255, 255, 255);
$dark: rgb(29, 29, 29);
$bordo: rgb(88, 26, 40);
$dark_brown: rgb(30, 18, 4);
$gray: rgb(178 176 163);

$screenHeight: 100vh;
$headerHeight: 100px;

@mixin breakpoint($point) {
  @if $point == burger {
    @media (min-width: 1290px) {
      @content;
    }
  }
  @if $point == xl {
    @media (max-width: 1289px) {
      @content;
    }
  }
  @if $point == sliderlg {
    @media (max-width: 54em) {
      @content;
    }
  }
  @if $point == lg {
    @media (max-width: 61em) {
      @content;
    }
  }
  @if $point == md {
    @media (max-width: 47em) {
      @content;
    }
  }
  @if $point == sm {
    @media (max-width: 36em) {
      @content;
    }
  }
  @if $point == xs {
    @media (max-width: 30em) {
      @content;
    }
  }
  @if $point == xxs {
    @media (max-width: 25em) {
      @content;
    }
  }
}
