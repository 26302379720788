@import './variables.scss';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  color: $black;
  background-color: $bg;
  font-family: $Josefin_Sans;
}

#root {
  height: 100%;
}
